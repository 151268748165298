export const APP_VERSION = '3.1.0';

export const ENABLE_REDUX_DEV_TOOLS = true;

export const THEMES = {
    LIGHT: 'LIGHT',
    DARK: 'DARK',
};

export const NOTIFICATION_TYPES = {
    SUCCESS: 'success',
    WARNING: 'warning',
    ERROR: 'error',
    PROGRESS: 'progress',
};

export const ACTIVITY_TYPES = {
    ROUTE: 'route',
    REQUEST: 'request',
    ERROR: 'error',
    SUCCESS: 'success',
    DLLICENSE: 'download_license',
    SOURCEURL: 'open_source_url',
    REQUESTGENAI: 'request_genai',
};

export const DOMAINS = [
    'evm.online',
    'ews.online',
    'evs.online',
    'videomanager.online',
    'anz.videomanager.online',
    'dev.videomanager.exc.motorolasolutions.com',
    'videomanager.ex.motorolasolutions.com',
    'videomanager.exc.motorolasolutions.com',
    'psos.evm.online',
    'br.evm.online',
    'pgd.evm.online',
];

export const AUTOMATION_BUCKET =
    process.env.REACT_APP_AWS_BRANCH === 'main' ||
    process.env.REACT_APP_AWS_BRANCH === 'staging'
        ? 'msi-automation'
        : 'msi-automation-dev';

export const INSTANCETYPE = ['t3.med/*  */ium', 'c5.large'];
export const GRAFANAURL =
    'https://grafana.videomanager.online/d/f158560d-4908-4f38-8e14-d0f71b1d352f/cloudops-support?orgId=1&refresh=5s&var-datasource=GRAFANADATASOURCE&var-region=REGION';
export const GRAFANAGOVURL =
    'https://grafana-usgov.videomanager.ex.motorolasolutions.com/d/f158560d-4908-4f38-8e14-d0f71b1d352f/cloudops-support?orgId=1&refresh=5s&var-datasource=GRAFANADATASOURCE&var-region=REGION';
export const ENDPOINT =
    process.env.REACT_APP_AWS_BRANCH === 'main' ||
    process.env.REACT_APP_AWS_BRANCH === 'staging'
        ? 'https://api.evm.online'
        : process.env.NODE_ENV === 'development'
          ? ''
          : 'https://api-dev.evm.online';

export const ENDPOINT_RMA_API =
    process.env.REACT_APP_AWS_BRANCH === 'main' ||
    process.env.REACT_APP_AWS_BRANCH === 'staging'
        ? 'https://iv9bvwazj0.execute-api.eu-west-2.amazonaws.com/Prod'
        : process.env.NODE_ENV === 'development'
          ? 'https://319cuib3e1.execute-api.eu-west-2.amazonaws.com/Prod'
          : 'https://319cuib3e1.execute-api.eu-west-2.amazonaws.com/Prod';

// create enums for the status
export const RMA_STATUS_ENUM = {
    ALL: 0,
    AUTHORIZED: 111,
    RECEIVED_ASSESSING: 121,
    PAYMENT_REQUESTED: 122,
    IN_REPAIR: 123,
    PENDING_INVOICE: 126,
    RMA_COMPLETE: 131,
    RMA_CANCELLED: 141,
    NEW_RMA: 101,
};

export const ORDER_STATUS_ENUM = {
    AWAITING_AUTHORISATION: 1,
    IN_MANUFACTURING: 11,
    PENDING_INVOICE: 21,
    ORDER_COMPLETE: 31,
    ORDER_CANCELLED: 41,
};

export const TRIAL_STATUS_ENUM = {
    PENDING: 201,
    ON_TRIAL: 211,
    OVERDUE: 221,
    TRIAL_COMPLETE: 231,
    TRIAL_CANCELLED: 241,
};

export const TRIAL_STATUS_NAME = {
    201: 'Pending',
    211: 'On Trial',
    221: 'Overdue',
    231: 'Trial Complete',
    241: 'Trial Cancelled',
};
export const ORDER_STATUS_NAME = {
    1: 'Awaiting Authorisation',
    11: 'In Manufacturing',
    21: 'Pending Invoice',
    31: 'Order Complete',
    41: 'Order Cancelled',
};

export const STATE = {
    AWAITING_AUTHORISATION: 1,
    IN_MANUFACTURING: 11,
    PENDING_INVOICE: 21,
    ORDER_COMPLETE: 31,
    ORDER_CANCELLED: 41,
    NEW_RMA: 101,
    AUTHORISED: 111,
    RECEIVED_AND_ASSESSING: 121,
    PAYMENT_REQUESTED: 122,
    IN_REPAIR: 123,
    RMA_PENDING_INVOICE: 126,
    RMA_COMPLETE: 131,
    RMA_CANCELLED: 141,
    RMA_ALL: 0,
    PENDING: 201,
    ON_TRIAL: 211,
    OVERDUE: 221,
    TRIAL_COMPLETE: 231,
    TRIAL_CANCELLED: 241,
};

export const RMA_STATUS = [
    {
        label: 'All',
        value: 0,
    },
    {
        label: 'Authorized',
        value: 111,
    },
    {
        label: 'Received & Assessing',
        value: 121,
    },
    {
        label: 'Payment Requested',
        value: 122,
    },
    {
        label: 'In Repair',
        value: 123,
    },
    {
        label: 'Pending Invoice',
        value: 126,
    },
    {
        label: 'RMA Complete',
        value: 131,
    },
    {
        label: 'RMA Cancelled',
        value: 141,
    },
];

export const ORDER_STATUS_SECONDARY = [
    {
        label: 'AVAILABLE',
        value: 1,
    },
    {
        label: 'HELD QA',
        value: 2,
    },
    {
        label: 'HELD CONFIG',
        value: 3,
    },
    {
        label: 'Urgent',
        value: 4,
    },
    {
        label: 'Forecast Only',
        value: 5,
    },
    {
        label: 'HELD - Material',
        value: 6,
    },
    {
        label: 'HELD - Purchase Order',
        value: 7,
    },
    {
        label: 'PART SHIPPED',
        value: 8,
    },
    {
        label: 'WORK IN PROGRESS',
        value: 9,
    },
];

export const ORDER_STATUS = [
    {
        label: 'Awaiting Authorisation',
        value: 1,
    },
    {
        label: 'In Manufacturing',
        value: 11,
    },
    {
        label: 'Pending Invoice',
        value: 21,
    },
    {
        label: 'Order Complete',
        value: 31,
    },
    {
        label: 'Order Cancelled',
        value: 41,
    },
];
export const TRIAL_STATUS = [
    {
        label: 'Pending',
        value: 201,
    },
    {
        label: 'On Trial',
        value: 211,
    },
    {
        label: 'Overdue',
        value: 221,
    },
    {
        label: 'Trial Complete',
        value: 231,
    },
    {
        label: 'Trial Cancelled',
        value: 241,
    },
];

export const SW_STATUS = [
    {
        label: 'Awaiting Authorisation',
        value: 1,
    },
    {
        label: 'In Manufacturing',
        value: 11,
    },
    {
        label: 'Pending Invoice',
        value: 21,
    },
    {
        label: 'Order Complete',
        value: 31,
    },
    {
        label: 'Order Cancelled',
        value: 41,
    },
    {
        label: 'New',
        value: 101,
    },
    {
        label: 'Authorised',
        value: 111,
    },
    {
        label: 'RMA Complete',
        value: 131,
    },
    {
        label: 'RMA Cancelled',
        value: 141,
    },
    {
        label: 'Pending',
        value: 201,
    },
    {
        label: 'On Trial',
        value: 211,
    },
    {
        label: 'Overdue',
        value: 221,
    },
    {
        label: 'Trial Complete',
        value: 231,
    },
    {
        label: 'Trial Cancelled',
        value: 241,
    },
    {
        label: 'Pending Invoice',
        value: 126,
    },
    {
        label: 'Received & Assessing',
        value: 121,
    },
    {
        label: 'Payment Requested',
        value: 122,
    },
    {
        label: 'In Repair',
        value: 123,
    },
];

export const TIER_OPTIONS = [
    {
        label: 'Tier 1 - Complex customers (2+3)',
        value: 1,
    },
    {
        label: 'Tier 2 - Needs customer notification',
        value: 2,
    },
    {
        label: 'Tier 3 - Can receive upgrades',
        value: 3,
    },
];

export const INSTANCE_STATE = [
    {
        label: 'Running',
        value: 'running',
    },
    {
        label: 'Stopped',
        value: 'stopped',
    },
];

export const GENERIC_AI_MODEL = [
    {
        label: 'ChatGPT 4',
        value: 'ChatGPT4',
        dataSetId: '',
    },

    {
        label: 'Dall E-3',
        value: 'MsiITDallE',
        dataSetId: '',
    },
    {
        label: 'Gemini 1.5 Flash (BETA)',
        value: 'VertexGemini',
        dataSetId: '',
    },
    {
        label: 'Claude Sonnet',
        value: 'Claude-Sonnet',
        dataSetId: '',
    },
];

export const VMEX_AI_MODEL = [
    {
        label: 'VMEX GenAI',
        value: 'VMEXGenAI',
        dataSetId: '',
    },
    // {
    //     label: 'VMEX-Cloud-Incidents',
    //     value: 'VertexGemini',
    //     dataSetId: '81488c8c-e540-4a13-aecc-eacfbf4a40bc',
    // },
];
